@import '../../base/modules';

.paginationList { 
    @extend .body-bold;
    margin: var(--40px) var(--20px);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--50px), auto));
    gap: var(--1px);
    justify-content: center;
}

.paginationItem {
    display: flex;
    justify-content: center;
    color: $color-black;
    border-radius: var(--5px);
    border: var(--1px) solid $color-copper;
    background-color: $color-white;
    margin-bottom: 0;

    a {
        display: flex;
        align-items: center;
        padding: var(--11px) var(--15px);
    }

    &:hover {
        background-color: darken($color-white, 30%);
    }

    &:active {
        border: var(--1px) solid $color-light-blue;
        background-color: $color-copper;
    }
}

.paginationItemActive {
    @extend .paginationItem;
    border: var(--1px) solid $color-light-blue;
    background-color: $color-copper;

    &:hover {
        background-color: darken($color-copper, 30%);
    }
}

.paginationItemNext {
    @extend .paginationItem;
    background-color: $color-light-blue; 

    &:hover {
        background-color: darken($color-light-blue, 30%);
    }
}

.tabContainer {
    display: flex;
    justify-content: center;
}

.tabList {
    display: grid;
    justify-content: space-between;
    gap: var(--60px);
    grid-auto-flow: column;
    border-bottom: 1.5px solid $color-white;
    width: auto;
    @media screen and (max-width: 960px){
		gap: var(--30px);
	}
}

.tab {
    @extend .headingTwo;
    cursor: pointer;
    color: $color-white;
    border-bottom: 3px solid transparent;
    padding: 0 var(--20px);
    margin-bottom: -1px;
    @media screen and (max-width: 960px){
		font-size: var(--16px);
        font-family: $font-accent;
	}
}

.tabSelected {
    border-bottom: 3px solid $color-mustard;
}

.resultsAmount {
    @extend .body-bold;
    display: flex;
    justify-content: center;
    margin: var(--40px) 0;
    color: $color-white;

    @media screen and (max-width: 960px){
		margin: var(--20px) 0 var(--48px) 0;
	}
}
