@import '../base/modules';

.VideoGallery {
  display: flex;
  gap: $gutter-large;
  min-height: 500px;
  position: relative;

  @media screen and (max-width: 1200px){
    flex-direction: column;
    min-height: 800px;
  }
}
.VideoPlayerWrap {
  border-radius: 20px;
  overflow: hidden;
  position: relative;

}
.VideoList {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: $gutter;

  overflow-y: auto;
  max-height: 520px;
  scrollbar-width: thin;
  scrollbar-color: $color-purple $color-white;
  padding-right: 20px;
  &::-webkit-scrollbar {
    width: 10px;
  
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-purple;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  
  @media screen and (max-width: 1200px) {
    height: 300px;
  }
}
.VideoListItem {
  display: flex;
  padding: $spacing-half;
  gap: $gutter;
  cursor: pointer;
  border-radius: 20px;
  align-items: center;
}
.VideoListItemActive {
  background: white;
  box-shadow: $ffs-shadow;
}
.VideoThumbnail {
  position: relative;
  width: 120px;
  border-radius: 20px;
  aspect-ratio: 4/3; 
  background: $color-light-grey;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
    height:35px;
    fill: $color-icon-grey;
  }
}
.VideoListItemDescription {
  display: flex;
  flex-direction: column;
  gap: $spacing-half;
}
.PlayVideo {
  color: $color-black;
  text-align: left;
  margin-right: auto;
}
.VideoListItemActive .PlayVideo {
  color: $color-peach;
}
.VideoListItemActive .VideoThumbnail svg{
  fill: $color-peach;
}
.VideoItemTitle {
  font-weight: 800;
  margin: 0;
  color: $color-dark-green;
}