@import '../base/_variables.scss';
//spin animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.SpinnerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid $color-peach;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}