@import '../../base/modules';

.Footer {
  min-height: 300px;
}
.ColumnsWrap {
  display: flex;
  justify-content: space-between;
  gap: $gutter-large;

  flex-wrap: wrap;
}
.Column {
  width: 100%;
  max-width: 233px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  flex: 1 1 150px;
  gap: $spacing;
}
.ColumnLinks {
  display: flex;
  flex-direction: column;
  gap: $spacing;
  svg {
    fill: $color-light-green;
    width: 30px;
    height: 30px;
  }
  color: white;
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.ColumnHeader {
  font-weight: 700;
  color: $color-light-green;
  font-size: 1.25rem;
  line-height: 1;
}
.ColumnItem {
  display: flex;
  gap: $spacing;
  align-items: center;
}
.LogoInvert img{
  filter: invert(1);
}