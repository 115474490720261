@import '../../base/variables.scss';


@mixin grid($num) {
  display: grid;
  grid-template-columns: repeat($num, 1fr);
  gap: $gutter;
  @media screen and (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}

.Grid1 {
  @include grid(1);
};
.Grid2 {
  @include grid(2);
};
.Grid3 {
  @include grid(3);
};
.Grid4 {
  @include grid(4);
};
.Grid5 {
  @include grid(5);
};