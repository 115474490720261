
html {
  /* -webkit-font-smoothing: antialiased; */
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
}

/* https://www.joshwcomeau.com/css/surprising-truth-about-pixels-and-accessibility/#leveraging-css-variables-15 */
:root {
    --1px: 0.0625rem;
    --5px: 0.3125rem;
    --10px: 0.625rem;
    --11px: 0.69rem;
    --14px: 0.875rem;
    --15px: 0.9375rem;
    --16px: 1rem;
    --17px: 1.0625rem;
    --18px: 1.125rem;
    --19px: 1.1875rem;
    --20px: 1.25rem;
    --21px: 1.3125rem;
    --25px: 1.5625rem;
    --30px: 1.88rem;
    --40px: 2.5rem;
    --48px: 3rem;
    --50px: 3.125rem;
    --60px: 3.75rem;

    --desktop: 1200px;
    --tablet: 1024px;
    --mobile: 768px;
}

::selection {
  background: $color-hl; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $color-hl; /* Gecko Browsers */
}

body {
  font-family: $font-primary;
  letter-spacing: 0;
  color: $color-black;
  background: linear-gradient(to bottom, 
                $color-dark-green 0%, 
                $color-dark-green 1200px, 
                $color-purple 100%);
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

#main {
  position: relative;
  z-index: 1;
}

button:focus,
a:focus,
select:focus,
summary:focus,
textarea:focus,
input:focus {
  outline: max(2px, 0.15em) solid #da6643;
  outline-offset: max(2px, 0.15em);
}

a {
  color: var($color-black);
}

a:hover,
a:focus {
  text-decoration: none;
}
  
section {
  padding: var(--60px) 0;
}

.Container {
  max-width: $width;
  position: relative;
  margin: 0 auto;
  box-sizing: content-box;
  padding: 0 $gutter;
}

@mixin line-clamp($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate1 {
  @include line-clamp(1);
}

.truncate2 {
  @include line-clamp(2);
}

.truncate3 {
  @include line-clamp(3);
}
.Box {
  border-radius: 20px;
  background: $color-white;
  box-shadow: $ffs-shadow;
}
.GreenBox {
  @extend .Box;
  background: $color-light-green;
}
.PeachBox {
  @extend .Box;
  background: $color-peach;
}

.HalfWidth {
  max-width: calc(50%  - $gutter);
  width: 100%;
  @media screen and (max-width: 1200px){
    max-width: 100%;
  }
}
.OneThirdsWidth {
  max-width: calc(33%  - $gutter);
  width: 100%;
  @media screen and (max-width: 1200px){
    max-width: 100%;
  }
}
.TwoThirdsWidth {
  max-width: calc(67%  - $gutter);
  width: 100%;
  @media screen and (max-width: 1200px){
    max-width: 100%;
  }
}
.CountryWrap {
  display: flex;
  align-self: start;
  gap: $spacing-half;
  margin-bottom: $spacing-half;
}
.Page {
  li,p {
    color: $color-white;
  }
  li {
    @extend p;
  }
  h1, h2, h3, h4, h5, h6 {
    color: $color-light-blue;
  }
  ul,ol {
    margin-left: $spacing;
  }
  li {
    margin-bottom: $spacing-half;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style-type: decimal;
  }
  pre {
    @extend p;
    background: $color-copper;
    padding: $gutter;
    border-radius: 20px;
    text-transform: none;
    color: $color-text;
  }
}
main,
header,
footer {
  margin: 0 auto;
}

.sliderTray {
  column-gap: 36px;
}

.contentPage {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: content-box;
  padding: var(--50px) var(--50px) 0;
  @media screen and (max-width: 960px){
    padding: var(--50px) var(--20px) 0;
  }
  .Container {
    margin: 0;
    padding: 0;
  }
}
