@import '../../base/modules';
@import '../button/button.module.scss';

.mapContainer{
  padding-top: .5rem;
}

.infoWindow{
  box-shadow: $ffs-shadow;
  height: calc(100% - 190px);
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 400;
  background: $color-peach;
  max-width: 33%;
  width: 538px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  h3 {
    color: $color-black;
    font-weight: 700;
  }
  @media screen and (max-width: 900px) {
    width: 80vw;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 68vh;
    top: 0;
  }
}
.scrollWrap {
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
  &::-webkit-scrollbar {
    width: 10px;
  
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-purple;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  scrollbar-width: 17px;
  scrollbar-color: $color-purple $color-white;
}

.buttonPrimary {
  display: inline-block;
  margin: 1rem 0;
  @extend .button;
  color: $color-text;
  text-decoration: none;
}

.marker {
  z-index: 10000;
  display: block;
  box-shadow: $ffs-shadow;
}

.headingOverlay {
  position: absolute;
  z-index: 100;
  max-width: 585px;
  backdrop-filter: blur(6px);
  padding: 1rem 1.25rem 1.25rem;
  background: #00000026;
  top: 20px;
  border-radius: 7px;
  h1 {
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
  a {
    color: #dacc3e;
    padding-left: 10px;
  }
  @media screen and (max-width: 900px) {
    width: 90vw;
    max-width: 100%;
    left: 0;
    top: 0;
    padding: 1rem;
    h1 {
      font-size: 1.15rem;
    }
    
  }
}