@import '../../base/modules';
@import '../button/button.module.scss';

.header {
  position: relative;
  z-index: 1000;
}

.skipLink {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  overflow: hidden;
  &:focus {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: auto;
    overflow: visible;
    background: $color-light-green;
    color: $color-text;
    padding: 1rem;
    z-index: 1000;
  }
}

.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  color: #fff;
  
  @media screen and (max-width: 1024px) { /* Show on small screens */
    padding: 1.5rem .5rem;
  }
}

.linkslist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  gap: 2rem;
  font-weight: 500;
  font-size: 1.02rem;

  li {
    margin-bottom: 0;
  }
  a {
    border-bottom: 1px solid transparent;
    background-image: linear-gradient(120deg, $color-light-green, $color-light-green);
    background-size: 0% 2px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.3s;
    padding: 0.5rem;
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    background-size: 100% 2px;
    color: $color-light-green;
  }
  li:last-of-type {
    @extend .buttonPrimary;
    @media screen and (max-width: 1024px) {
      padding: .5rem;
    }
    @media screen and (min-width: 1024px) {
      margin-left: 2rem;
    }
    a {
      color: $color-text;
    }
    &:hover {
      background: $color-light-green;
      a {
        color: $color-text;
      }
    }
  }
  
  @media screen and (max-width: 1024px) { /* Show on small screens */
    margin: 0 1rem 0 auto;
    a {
      font-size: .9rem;
    }

    li:not(:last-of-type) {
      display: none; /* Hide by default */
    }
    &.open li:not(:last-of-type) {
      display: block;
    }
    &.open li:last-of-type {
      margin-top: 2rem;
    }
  }
  &.open {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem 2rem;
    width: 100vw;
    min-height: 100vh;
    background: $color-dark-green;
    margin: 0;
    box-sizing: border-box;
    padding-top: 100px;
    a {
      padding: 1rem;
      display: block;
    }
  }
}

.logo {
  max-width: 156px;
  position: relative;
  z-index: 100;
  @media screen and (max-width: 1024px) { /* Show on small screens */
    max-width: 120px;
  }
}

.mobileMenuButton {
  display: none; /* Hide by default */
  background: none;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #fff;
  padding: 0.71rem 1rem 0.75rem;
  border-radius: 5px;
  position: relative;
  z-index: 1;

  &:hover {
    background: rgba (255,255,255,0.1);
  }
}

@media screen and (max-width: 1024px) { /* Show on small screens */
  .mobileMenuButton {
    display: block;
  }
}