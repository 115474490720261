@import '../../base/modules';

.button {
    @extend .body-bold;
    color: $color-bronze;
    display: flex;
    align-items: center;
    gap: var(--10px);
}

.buttonPrimary {
    @extend .body-bold;
    padding: var(--10px) var(--15px);
    text-align: center;
    background-color: $color-light-blue;
    border-radius: var(--5px);
    cursor: pointer;
    color: $color-text;
    text-decoration: none;
}

.buttonSecondary {
    @extend .buttonPrimary;
    border-radius: 5px;
    border: 1px solid $color-black;
    background-color: transparent;
    color: $color-text;
}
