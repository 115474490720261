@import '../../base/modules';
@import '../button/button.module.scss';

.Card {
  background: white;
  box-shadow: $ffs-shadow;
  padding: .5rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
  gap: $gutter;
}
.CardPreview {
  @extend .Card;
  display: flex;
  gap: $gutter;
  align-items: center
}

h3 {
  margin: .75rem 0 1rem;
  color: $color-dark-green;
}
.heading {
  margin: .5rem 0 .25rem;
  color: $color-dark-green;
}
.sdg {
  margin-bottom: .5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  strong {
    margin-right: .5rem;
  }
  img{
    border-radius: 5px;
    overflow: hidden;
  }
}
.truncate,
.truncate1 {
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: .25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.values {
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.logoImage {
  text-align: center;
  height: 200px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  img {
    opacity: 1;
    
  }
}
.logoImageSquare {
  text-align: center;
  height: 100px;
  max-width: 100px;
  aspect-ratio: 1/1;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  flex-shrink: 0;
  img {
    opacity: 1;
    
  }
}
.logoImageInner {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.placeholder {
  background: $color-light-green;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  svg {
    fill: $color-peach;
    width: 100px;
    height: 100px;
  }
}