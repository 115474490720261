@import '../../base/modules';


.VideoGalleryWrap {
  @extend .GreenBox;
  padding: $gutter-large;

  display: flex;
  flex-direction: column;
  gap: $gutter-large;

  h2 {
    margin: 0;
  }
}

.flex {
  gap: 4rem;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1024px){
    display: flex;
    > div {
      width: 50%;
    }
  }
}

.logo {
  max-width: 200px;
  border-radius: 4px;
  overflow: hidden;
}
.logoImage {
  text-align: center;
  height: 300px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  img {
    opacity: 1;
    
  }
}
.Caption {
  color: $color-light-blue;
}
.featuredImage {
  text-align: right;
  img {
    display: inline-block;
  }
  > div {
    box-shadow: $ffs-shadow;
    max-width: 600px;
    max-height: 500px;
    overflow: hidden;
    border-radius: 20px;
  }
}

.textWrapper {
  color: #fff;
  max-width: 65ch;
  font-size: 1rem;
  h3{
    color: #fff;
    margin: 2rem 0 .5rem;
  }
  > div {
    margin-bottom: 2rem;
  }
}

.sr {
  opacity: 0;
  position: absolute;
}

.sdgIcons {
  display: flex;
  gap: 1rem;
  width: 100%;
  span {
    display: inline-block;
    border-radius: 4px;
    box-shadow: $ffs-shadow;
    overflow: hidden;
  }
}

.details {
  background: $color-peach;
  border-radius: 20px;
  padding: 2rem 2rem 3rem;
  box-shadow: $ffs-shadow;
  justify-content: flex-start;

  @media screen and (min-width: 1024px){
    display: flex;
    gap: 4rem;
    > div {
      width: 50%;
    }
  }
  h3 {
    color: #fff;
  } 
  p {
    color: $color-text;
  }
  a {
    color: $color-text;
    text-decoration: none;
  }
}

.contact {
  a {
    display: inline-block;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    &:hover {
      text-decoration: underline;
    }
  }
}

.social {
  a:hover {
    svg {
      fill: $color-white;
    }
    text-decoration: none;
  }
  svg{
    font-size: 2rem;
    
    padding: .5rem .5rem .5rem 0;
  }

}
.IconHeader {
  display: flex;
  align-items: center;
  gap: $spacing-half;
  margin: 2rem 0;
  h3 {
    margin: 0;
  }
}
