@import '../base/modules';

@mixin slideStyle($num) {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: calc(( 1440px / #{$num} ) - ($gutter-large * 2));
  list-style-type: none;
  margin: 0 1rem 0 0;
  gap: 2rem;
  padding: 0;
  flex-shrink: 0;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin: 0;
  }
}

.Wrap {
  position: relative;
}
.AboveCarousel {
  display: flex;
  align-items: center;
  gap: $gutter-large;
  justify-content: space-between;
  margin-bottom: $gutter-large;
  color: white;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.Nav {
  display: flex;
  align-items: stretch;
  background: $color-light-blue;
  flex-shrink: 0;
  width: fit-content;
  height: 50px;
  border-radius: 10px;
  button {
    background: none;
    color: #000;
    height: 100%;
    border-width: 0;
  }
  
  svg {
    height: 100%;
  }
  span {
    min-width: 69px;
    text-align: center;
  }
  span, button {
    font-size: 1.25rem;
    color: #000;
    padding: 12px;
  }
  button:hover svg{
    color: $color-peach;
  }
}
.Btn {
  cursor: pointer;

  &[disabled] {
    svg {
      fill: $color-disable;
    }
  }
}
.NextBtn {
  @extend .Btn;
}

.PrevBtn {
  @extend .Btn;
}

.SlideTray {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  margin:  $gutter-large 0px;
  overflow: hidden;
}
.Default {
  display: flex;
  list-style-type: none;
  margin: 0 $gutter-large;
  padding: 0;
  flex-shrink: 0;
}
.OneSlide .Slide {
  @include slideStyle(1);
}
.TwoSlides .Slide {
  @include slideStyle(2);
}
.ThreeSlides .Slide {
  @include slideStyle(3);
}
.FourSlides .Slide {
  @include slideStyle(4);
}
.FiveSlides .Slide {
  @include slideStyle(5);
}
