h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  margin: 1rem 0;
  line-height: 1.5;
  font-weight: 900;
}

h1,.headingOne {
  font-family: $font-heading;
  font-size: var(--30px);
  line-height: 130%;
  color: #fff;
  max-width: 640px;
  font-weight: 400;
}

h2,.headingTwo {
  font-family: $font-heading;
  font-weight: 700;
  font-size: var(--25px);
  line-height: 130%;
}

h3,.headingThree {
  font-family: $font-heading;
  font-weight: 700;
  font-size: var(--20px);
  line-height: 150%;
}

h4,.headingFour {
  font-family: $font-heading;
  font-weight: 700;
  font-size: var(--18px);
  line-height: 150%;
}

h5,.headingFive,h6,.headingSix {
  font-family: $font-body;
  font-weight: 400;
  font-size: var(--18px);
  line-height: 150%;
}

p,.body {
  font-family: $font-body;
  font-weight: 400;
  font-size: var(--18px);
  line-height: 150%;
}

.body-bold {
  font-family: $font-body;
  font-weight: 700;
  font-size: var(--16px);
  line-height: 150%;
}

.nav-links {
  font-family: $font-accent;
  font-weight: 600;
  font-size: var(--18px);
  line-height: 150%;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

ol,
ul {
  margin: 0;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}
