/* Fonts */

.outfit-ffs {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.manrope-ffs {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

$font-heading: 'Outfit', sans-serif;
$font-accent: 'Manrope', sans-serif;
$font-body: 'Manrope', sans-serif;
$font-primary: $font-body;
