/* Break points */
$desktop: 1200px;
$tablet: 1024px;
$mobile: 768px;
$width: 1440px;
$slices: 12;

/* Sizes */
$spacing: 20px;
$spacing-half: 10px;
$spacing-quarter: 5px;

$gutter: 20px;
$gutter-large: 40px;

/* Colors */
$color-black: #0D1321;
$color-white: #ffffff;
$color-peach: #C97064;
$color-light-grey: #E0E0E0;
$color-grey: #414A4F;
$color-dark-green: #145A51;
$color-green: #B0D8D1;
$color-light-green: #C8D6B2; /* Primary Brand Green */
$color-light-blue: #92DCE5;
$color-mustard: #DACC3E;
$color-copper: #C97064;
$color-bronze: #AD6055;
$color-purple: #373D5A;

$color-text: #000000;
$color-text-alt: #ffffff;
$color-bg: #EFFDFF;
$color-icon-grey: #596070;
$color-hl: $color-light-green;

$color-disable: #BDBDBD;

/* Effects */
$ffs-shadow: 0px 179px 50px 0px rgba(0, 0, 0, 0.00), 0px 114px 46px 0px rgba(0, 0, 0, 0.01), 0px 64px 39px 0px rgba(0, 0, 0, 0.05), 0px 29px 29px 0px rgba(0, 0, 0, 0.09), 0px 7px 16px 0px rgba(0, 0, 0, 0.10);


@function width($num) {
	@return 100% / $slices * $num;
}
