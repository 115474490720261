@import '../../base/modules';
@import '../button/button.module.scss';


.FilterWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1400px;
    width: 100%;
    padding: .5rem $gutter;
    left: 0;
    box-sizing: border-box;
}
.Sticky {
    position: sticky;
    top: $gutter;
    z-index: 500;
}
.FilterWrapDesktop {
    @extend .FilterWrap;
    @extend .Sticky;
    
    @media screen and (min-width: 1024px) {
        margin: -120px auto 0;
    }
    @media screen and (max-width: 1024px) {
        margin: 0 auto 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
    }
    
}

.FilterWrapMobile{
    @media  screen and (max-width: 1024px) {
        width: fit-content;
        height: 0;
        margin: 0 auto;
        padding: 0;
        overflow: visible;
    }
   
    
}
.FilterWrapHidden {
    @media  screen and (max-width: 1024px) {
        display: none;
    }
}
.FilterBar {
    width: 100%;
    box-shadow: $ffs-shadow;
    padding: var(--15px);
    background: $color-copper;
    border-radius: 20px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $gutter;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px) {
        flex-direction: column;  
    }

    .react-select__placeholder {
        color: $color-text;
    }
    
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
} 
.SelectInput {
    > div {
        overflow: hidden;
        border-radius: 20px;
    }
    > div div{
        color: $color-text;
        font-size: 1rem;
    }
    &::placeholder {
        color: $color-text;
    }
    > div span {
        display: none;
    }
    .react-select__placeholder {
        color: $color-text;
    }
    > div:focus {
        outline: max(2px, 0.15em) solid #92dce5;
        outline-offset: max(2px, 0.15em);
    }
}
.SelectDropdownIndicator {
    padding-left: 0;
    padding-right: 0;
}
.react-select__control {
    border-radius: 20px;
    > div div{
        color: $color-text;   
    }
    &::placeholder {
        color: $color-text;
    }
    > div span {
        display: none;
    }
    .react-select__placeholder {
        color: $color-text;
    }
}

.Input {
    border-radius: 20px;
    background: white; 
    height: 50px;
    max-width: 100% ;
    width: 225px;
    padding: $spacing-quarter $spacing-half;
    box-sizing: border-box;
    color: $color-text;
    &::placeholder {
        color: $color-text;
    }
}

.SearchInput {
    min-width: 257px;
    &:focus {
        outline-color: $color-light-blue;
    }
}

.SearchLabel {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.SelectControl {
    @extend .Input;
}
.DoneButton, .ExpandButton {
    display: flex;
    color: $color-text;
    @media  screen and (min-width: 1024px) {
        display: none;
    }
}
.ExpandButton {
    position: fixed;
    bottom: $gutter;
    right: $gutter;
    margin-left: auto;
    z-index: 1000000;

}
.SearchInputWrap {
    position: relative;
}

.SearchIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.SearchInput {
    padding-right: 30px;
    @extend .Input;
}
.BtnWrap {
    display: flex;
    gap: $spacing-half;

}