@import '../../base/modules';
@import './../button/button.module.scss';

.Hero {

  .Hero__flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 3.125em;

    @media (min-width: 768px) {
      flex-direction: row;
      text-align: left;
    }

    @media (min-width: 1024px) {
      gap: 12.5em;
    }
  }
  
  .image_right,
  .image_left,
  .header_and_text {
    white-space: pre-wrap;
    @media (min-width: 768px) {
      flex: 1;
      max-width: none;
    }
  }

  .image_right {
    @media (min-width: 768px) {
      order: 2;
    }
  }
}


.TextSection{
	padding: 50px 0;
	
	h2{
		margin-bottom: 20px;
	}
	h5{
		margin-bottom: 0.5rem;

	}
	p{
		margin-bottom: 1.5rem;
		line-height: 1.2;
	}
	iframe{
		margin: 0 auto;
		display: flex;
		max-width: 100%;
		margin-bottom: 40px;
	}
}
.NarrowContainer {
  @extend .Container;
  max-width: 1000px;
  margin: 0 auto;
}


.Slices_Container {
  @extend .Container;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5em 0;
  @media (min-width: 768px) {
    margin: 6.25em auto;
  }
}

.Slices_Container_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: 5em 0;

  @media (min-width: 768px) {
    margin: 6.25em auto;
  }
}

.Cards__text {
  text-align: center;
  margin-bottom: 4.375em;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    width: 75%;
    margin-bottom: 5em;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
}

.align_center {
  display: inline-flex;
  align-items: center;
}

.icon_margin {
  margin-left: 0.625em;
}

.mb_8 {
  margin-bottom: 2em;
}

.card {
  padding: 2.5em 1.563em;
  border-radius: 0.313em;
  border: 0.063em solid $color-light-blue;
  background: $color-white;
  h2, h3, h4, h5, h6 {
    color: $color-peach;
  }
  p {
    color: $color-text;
  }
  @media (min-width: 1024px) {
    padding: 3.75em;
  }
}

.card_text {
  white-space: pre-wrap;
  margin-top: 2.5em;
  margin-bottom: 1.25em;
}


.CardsImages {
  .CardsImages__Container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5em;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.25em 2.5em;
    }

    @media (min-width: 1024px) {
      gap: 6em 5.375em;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 0.625em;
      padding: 1.25em;
      border: 0.063em solid $color-light-blue;
      box-shadow: 10px 12px 24px 0px rgba(206, 222, 238, 0.47);
    }

    .card_image {
      border-radius: 0.625em;
      width: 100%;
      height: 21.875em;
    }

    .card_text {
      padding: 1.875em 0;
      white-space: pre-wrap;
    }
  }
}


.CardsIcons {
  .CardsIcons_Container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5em;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.25em 2.5em;
    }

    @media (min-width: 1024px) {
      grid-gap: 3.75em 6.25em;
      width: 70%;
    }
  }
}

.CtaQuote {
  .CtaQuote_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.25em;
    padding: 4.375em 1.875em;

    border-radius: 0.313em;
    border: 0.063em solid $color-light-blue;
    box-shadow: 10px 12px 24px 0px rgba(206, 222, 238, 0.47);
    background: $color-white;

    h2, h3, h4, h5, h6 {
      color: $color-peach;
    }
    p {
      color: $color-text;
    }
    color: $color-text;
    @media (min-width: 1024px) {
      padding: 6.25em 0;
      width: 90%;
    }

    p {
      @media (min-width: 768px) {
        width: 80%;
      }

      @media (min-width: 1024px) {
        width: 50%;
      }
    }

    .quote {
      line-height: 150%; /* 45px */
      @media (min-width: 1024px) {
        font-size: 1.625rem;
      }
    }

    .author {
      font-weight: bold;
    }
  }
}

.Cta {
  .Slices_Container.Container {
    margin: 5em 0;
    padding: 0;
    z-index: 0;
    background: $color-white;
    h2, h3, h4, h5, h6 {
      color: $color-peach;
    }
    p {
      color: $color-text;
    }
    @media (min-width: 768px) {
      margin: 6.25em auto;
    }
  }

  .Cta_Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6.25em 1.875em;
    white-space: pre-wrap;
    @media (min-width: 768px) {
      padding: 3.75em 0;
    }

    p {
      margin-bottom: 1.25em;

      @media (min-width: 768px) {
        width: 80%;
      }

      @media (min-width: 1024px) {
        width: 50%;
      }
    }
  }
}

.ImageText {
  .ImageText__flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 3.75em;
    white-space: pre-wrap;
    @media (min-width: 768px) {
      flex-direction: row;
      text-align: left;
    }

    @media (min-width: 1024px) {
      gap: 12.5em;
    }

    .image_container {
      display: flex;
      justify-content: center;
      align-content: center;
    }

    img {
      height: 35em;
      width: 100%;
      object-fit: cover;
    }

    .image_right,
    .image_left,
    .header_and_text {
      @media (min-width: 768px) {
        flex: 1;
        max-width: none;
      }
    }

    .image_right {
      @media (min-width: 768px) {
        order: 2;
      }
    }

    .image_left {
      @media (min-width: 768px) {
        margin-right: 0;
      }
    }
  }

  .btn_container {
    margin-top: 1.875em;
  }
}

.stats_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5em;
  margin-top: 1.25em;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.25em 2.5em;
    margin-top: 3.75em;
  }

  @media (min-width: 1024px) {
    grid-gap: 6em 5.375em;
  }

  .major_stat {
    font-weight: 700;
    font-size: 2.5rem;
    color: $color-peach;
  }
  .minor_stat {
    color: $color-white;
  }
}

.TextButtons {
  .Cards__text {
    text-align: left;
  }
}


.List {
  padding: 10px 0;
}

.list {
  line-height: 200%;
}

.list_heading {
  max-width: 513px;
}

.two_columns_list ol{
  column-count: 2;
  @media (max-width: 768px) {
    column-count: 1;
  }
  li {
    padding-right: 200px;
    @media (max-width: 1024px) {
      padding-right: 100px;
    }
  }
}

.two_columns_list ul {
  column-count: 2;
  @media (max-width: 768px) {
    column-count: 1;
  }
  li {
    padding-right: 200px;
    @media (max-width: 1024px) {
      padding-right: 100px;
    }
  }
}

.three_columns_list ul,
.three_columns_list ol {
  column-count: 3;
  @media (max-width: 1024px) {
    column-count: 2;
  }
  @media (max-width: 768px) {
    column-count: 1;
  }
  li {
    padding-right: 100px;
  }
}

.four_columns_list ul,
.four_columns_list ol {
  column-gap: 10px;
  column-count: 4;
  @media (max-width: 1024px) {
    column-count: 2;
  }
  @media (max-width: 768px) {
    column-count: 1;
  }
  li {
    padding-right: 50px;
  }
}

.read_more {
  @extend .button;
  display: block;
  border: none;
  padding: $gutter 0;
  text-decoration: underline;
  font-weight: bold;
  background: none;

  @media (min-width: 768px) {
    display: none;
  }
}
